import { createSelector } from '@reduxjs/toolkit';
import { UserState } from './types';
import { Socials as SocialsTypes } from '@/src/shared/types/socials';
import { isBasicPlan } from '@/src/core/utils/plan';
import { UpgradePlans } from '@/types/subscription';
import { SubscriptionPlanConfigs } from '@/src/core/constants/subscriptionPlans';
import { getUserTypeIdName } from '@/src/shared/utils/user';

export const userSelector = (state) => state.user as UserState;

/**
 * This is just for development purposes
 * Don't include it on the real pages
 */
export const selectUser = createSelector(userSelector, (user) => user);

export const selectUserInfo = createSelector(userSelector, (user) => user.info);

export const selectIsAuthenticated = createSelector(
  selectUserInfo,
  (userInfo) => userInfo != null
);

export const selectUserToken = createSelector(
  userSelector,
  (user) => user.token
);

export const selectIsUserLoggedIn = createSelector(
  selectUserInfo,
  (user) => user !== null
);

export const selectIsFreeUser = createSelector(
  selectUserInfo,
  (userInfo) => userInfo?.subscription === 'none'
);

export const selectIsBasicUser = createSelector(
  selectUserInfo,
  (userInfo) => userInfo?.subscription === 'basic'
);

export const selectIsBasicPlanHidden = createSelector(
  selectUserInfo,
  (userInfo) => {
    return userInfo?.hide_basic_plan ?? false;
  }
);

export const selectIsNewAgent = createSelector(selectUserInfo, (userInfo) => {
  return userInfo?.is_new_agent ?? false;
});

export interface INextPlan {
  key: UpgradePlans;
  value: string;
}

export const selectNextPlanName = createSelector(
  [selectUserInfo, selectIsBasicPlanHidden, selectIsFreeUser],
  (userInfo, isBasicPlanHidden, isFreeUser): INextPlan | null => {
    const { subscription, trial } = userInfo ?? {};
    if (subscription === UpgradePlans.basic && trial.is_trial) {
      return {
        key: isBasicPlanHidden ? UpgradePlans.premium : UpgradePlans.basic,
        value: SubscriptionPlanConfigs.basic.name,
      };
    }

    if (
      subscription === UpgradePlans.basic ||
      (isFreeUser && isBasicPlanHidden)
    ) {
      return {
        key: UpgradePlans.premium,
        value: SubscriptionPlanConfigs.premium.name,
      };
    }
    if (subscription === UpgradePlans.premium) {
      return {
        key: UpgradePlans.platinum,
        value: SubscriptionPlanConfigs.platinum.name,
      };
    }
    if (subscription === UpgradePlans.platinum) {
      return {
        key: UpgradePlans.diamond,
        value: SubscriptionPlanConfigs.diamond.name,
      };
    }
    if (subscription === UpgradePlans.diamond) {
      return null;
    }
    return {
      key: UpgradePlans.basic,
      value: SubscriptionPlanConfigs.basic.name,
    };
  }
);

export const selectIsLimitedConnect = createSelector(
  selectUserInfo,
  (userInfo) => userInfo?.subscription == 'none'
);

export const selectIsConntected = createSelector(selectUserInfo, (userInfo) => {
  if (
    userInfo?.user_accounts.facebook.enabled ||
    userInfo?.user_accounts.facebook_2.enabled ||
    userInfo?.user_accounts['facebook-profile'].enabled ||
    userInfo?.user_accounts.twitter.enabled ||
    userInfo?.user_accounts.linkedin.enabled ||
    userInfo?.user_accounts.instagram.enabled ||
    userInfo?.user_accounts.youtube.enabled ||
    userInfo?.user_accounts.tiktok.enabled
  ) {
    return true;
  }
  return false;
});

export const selectIsAllPlatformsConnected = createSelector(
  selectUserInfo,
  (userInfo) =>
    userInfo?.user_accounts.facebook.connected &&
    userInfo?.user_accounts.facebook_2.connected &&
    userInfo?.user_accounts['facebook-profile'].connected &&
    userInfo?.user_accounts.twitter.connected &&
    userInfo?.user_accounts.linkedin.connected &&
    userInfo?.user_accounts.instagram.connected &&
    userInfo?.user_accounts.youtube.connected &&
    userInfo?.user_accounts.tiktok.connected
);

export const selectConnectedPlatforms = createSelector(
  selectUserInfo,
  (userInfo) => {
    const connectedPlatforms = [];
    if (userInfo?.user_accounts.facebook.enabled)
      connectedPlatforms.push('facebook');
    if (userInfo?.user_accounts.facebook_2.enabled)
      connectedPlatforms.push('facebook_2');
    if (userInfo?.user_accounts['facebook-profile'].enabled)
      connectedPlatforms.push('facebook-profile');
    if (userInfo?.user_accounts.twitter.enabled)
      connectedPlatforms.push('twitter');
    if (userInfo?.user_accounts.linkedin.enabled)
      connectedPlatforms.push('linkedin');
    if (userInfo?.user_accounts.instagram.enabled)
      connectedPlatforms.push('instagram');
    if (userInfo?.user_accounts.youtube.enabled)
      connectedPlatforms.push('youtube');
    if (userInfo?.user_accounts.tiktok.enabled)
      connectedPlatforms.push('tiktok');
    return connectedPlatforms;
  }
);

export const selectActivePlatforms = createSelector(
  selectUserInfo,
  (userInfo) => {
    const connectedPlatforms = [];
    if (userInfo?.user_accounts.facebook.connected)
      connectedPlatforms.push('facebook');
    if (userInfo?.user_accounts.facebook_2.connected)
      connectedPlatforms.push('facebook_2');
    if (userInfo?.user_accounts['facebook-profile'].connected)
      connectedPlatforms.push('facebook-profile');
    if (userInfo?.user_accounts.twitter.connected)
      connectedPlatforms.push('twitter');
    if (userInfo?.user_accounts.linkedin.connected)
      connectedPlatforms.push('linkedin');
    if (userInfo?.user_accounts.instagram.connected)
      connectedPlatforms.push('instagram');
    if (userInfo?.user_accounts.youtube.connected)
      connectedPlatforms.push('youtube');
    if (userInfo?.user_accounts.tiktok.connected)
      connectedPlatforms.push('tiktok');
    return connectedPlatforms;
  }
);

// Favor Instagram Business over Instagram
export const selectSharingInstagramPlatform = createSelector(
  selectUserInfo,
  (userInfo) => {
    if (
      userInfo?.user_accounts[SocialsTypes.InstagramBusiness].enabled &&
      !userInfo?.user_accounts[SocialsTypes.InstagramBusiness].token_expired
    )
      return SocialsTypes.InstagramBusiness;

    return SocialsTypes.Instagram;
  }
);

export const selectUserSubscription = createSelector(
  selectUserInfo,
  (userInfo) => userInfo?.subscription
);

export const selectIsPlanUpgrade = createSelector(
  selectUserInfo,
  (userInfo) => userInfo?.subscription !== 'none'
);

export const selectIsPaidUser = createSelector(
  selectUserInfo,
  (userInfo) => userInfo?.subscription !== 'none'
);

export const selectIsPlatinumOrHigherUser = createSelector(
  selectUserInfo,
  (userInfo) => {
    return ['platinum', 'diamond'].includes(userInfo?.subscription);
  }
);

export const selectIsPremiumOrHigherUser = createSelector(
  selectUserInfo,
  (userInfo) => {
    return ['premium', 'platinum', 'diamond'].includes(userInfo?.subscription);
  }
);

export const selectIsBasicOrHigherUser = createSelector(
  selectUserInfo,
  (userInfo) => {
    return ['basic', 'premium', 'platinum', 'diamond'].includes(
      userInfo?.subscription
    );
  }
);

export const selectIsProUser = createSelector(selectUserInfo, (userInfo) =>
  isBasicPlan(userInfo?.subscription)
);

export const selectIsPlatinumUser = createSelector(
  selectUserInfo,
  (userInfo) => userInfo?.subscription === 'platinum'
);

export const selectIsPremiumUser = createSelector(
  selectUserInfo,
  (userInfo) => userInfo?.subscription === 'premium'
);

export const selectIsDiamondUser = createSelector(
  selectUserInfo,
  (userInfo) => userInfo?.subscription === 'diamond'
);

export const selectIsCalendarConnected = createSelector(
  selectUserInfo,
  (userInfo) => userInfo?.calendar_scheduling == 1
);

export const selectHavePlatinumIntroOutro = createSelector(
  selectUserInfo,
  (userInfo) => {
    return userInfo.introUrl?.length > 0 && userInfo.outroUrl?.length > 0;
  }
);

export const selectIsMortgageBroker = createSelector(
  selectUserInfo,
  (userInfo) => {
    return getUserTypeIdName(userInfo?.user_type_id) === 'mortgage';
  }
);

export const selectUserType = createSelector(selectUserInfo, (userInfo) => {
  return getUserTypeIdName(userInfo?.user_type_id);
});

export const selectIsFinancialAdvisor = createSelector(
  selectUserInfo,
  (userInfo) => {
    return getUserTypeIdName(userInfo?.user_type_id) === 'finance';
  }
);

export const selectIsBroker = createSelector(selectUserInfo, (userInfo) => {
  return getUserTypeIdName(userInfo?.user_type_id) === 'broker';
});

export const selectIsInsuranceUser = createSelector(
  selectUserInfo,
  (userInfo) => {
    return getUserTypeIdName(userInfo?.user_type_id) === 'insurance';
  }
);

export const selectUserLowerPlans = createSelector(
  selectUserSubscription,
  (subscription) => {
    switch (subscription) {
      case UpgradePlans.basic:
        return [];
      case UpgradePlans.premium:
        return [UpgradePlans.basic];
      case UpgradePlans.platinum:
        return [UpgradePlans.basic, UpgradePlans.premium];
      case UpgradePlans.diamond:
        return [
          UpgradePlans.basic,
          UpgradePlans.premium,
          UpgradePlans.platinum,
        ];
      default:
        return [];
    }
  }
);

export const selectCanCreateCustomIntro = createSelector(
  selectUserInfo,
  (userInfo) => Boolean(userInfo?.can_create_video_intro?.status)
);
