import React, { useState } from 'react';
import dynamic from 'next/dynamic';

import { GeoLocationResult } from '@/src/containers/Landing/utils/geoVideo/types';
import Footer from '@/src/shared/components/Footer';
import { HeroSection } from '@/src/containers/HomeV6/components/HeroSection';
import { useHome } from '@/src/containers/HomeV6/components/HomeProvider';
import { useIsMobile } from '@/src/shared/hooks/utils/useIsMobile';

const SolutionsSection = dynamic(
  () => import('@/src/containers/HomeV6/components/SolutionsSection')
);
const AsSeenSection = dynamic(
  () => import('@/src/containers/HomeV6/components/AsSeenSection')
);
const ChooseRoomvuSection = dynamic(
  () => import('@/src/containers/HomeV6/components/ChooseRoomvuSection')
);
const HowToWinSection = dynamic(
  () => import('@/src/containers/HomeV6/components/HowToWinSection')
);
const FeaturesSection = dynamic(
  () => import('@/src/containers/HomeV6/components/FeaturesSection')
);
const WhatAgentsSaySection = dynamic(
  () => import('@/src/containers/HomeV6/components/WhatAgentsSaySection')
);
const JoinSection = dynamic(
  () => import('@/src/containers/HomeV6/components/JoinSection')
);
const LeadersSection = dynamic(
  () => import('@/src/containers/HomeV6/components/LeadersSection')
);
const AgentSection = dynamic(
  () => import('@/src/containers/HomeV6/components/AgentSection')
);
const InView = dynamic(() => import('@/src/shared/components/InView'));

type Props = Pick<
  GeoLocationResult,
  | 'usersCount'
  | 'video'
  | 'regions'
  | 'detectedRegion'
  | 'default_region_name'
  | 'ip_location_details'
>;

export function HomePageContent({
  usersCount,
  video,
  regions,
  detectedRegion,
  default_region_name,
  ip_location_details,
}: Props) {
  const [isSecondFoldVisible, setIsSecondFoldVisible] = useState(false);
  const isMobile = useIsMobile();
  const { openRegistrationPopup } = useHome();

  const handleCtaClick = () => {
    openRegistrationPopup();
  };

  return (
    <>
      <HeroSection
        regions={regions}
        defaultRegionName={default_region_name}
        detectedRegion={detectedRegion}
        ipLocationDetails={ip_location_details}
        video={video}
      />
      {!isMobile && <SolutionsSection />}
      <AsSeenSection />

      {!isSecondFoldVisible && (
        <InView
          rootMargin="150px"
          onVisible={() => {
            setIsSecondFoldVisible(true);
          }}
        />
      )}

      {isSecondFoldVisible && (
        <>
          <ChooseRoomvuSection />
          <LeadersSection />
          <HowToWinSection />
          <AgentSection />
          <FeaturesSection />
          {!isMobile && <WhatAgentsSaySection />}
          <JoinSection numberOfUsers={usersCount} onCtaClick={handleCtaClick} />
          <Footer />
        </>
      )}
    </>
  );
}
