import React from 'react';
import HomeHeader from '@/src/shared/components/HomeHeader';
import { wrapper } from '@/redux/store';
import { withAuthentication } from '@/src/core/utils/authentication';
import { selectIsAuthenticated } from '@/redux/user/selectors';
import {
  getMustDisplayHomePageRoute,
  shouldUseHomePage,
} from '@/src/shared/utils/abTesting/homepage';
import { getDynamicInfoForVideoLandingPage } from '@/features/geovideo';
import { withReactDetectDevice } from '@/src/core/utils/ssr/withDetectDevice';
import { GeoLocationResult } from '@/src/containers/Landing/utils/geoVideo/types';
import PopupManager from '@/src/core/components/PopupManager';
import { getIp } from '@/src/shared/utils/ssr/getIp';
import { fetchUserLocation } from '@/src/data/landing/useUserLocation';
import { redirectTo } from '@/src/shared/utils/ssr/redirectTo';
import { useEventProvider } from '@/helpers/event-context';
import { Pages } from '@/types/pages';
import { HomeProvider } from '@/src/containers/HomeV6/components/HomeProvider';
import GoogleOneTap from '@/src/core/components/GoogleOneTapLogin/GoogleOneTapLogin';
import { dehydrate, QueryClient } from '@tanstack/react-query';
import {
  fetchGoogleReviews,
  googleReviewsQueryKey,
} from '@/src/data/google-reviews/useGoogleReviews';
import { HomePageContent } from '@/src/containers/HomeV6/components/HomePageContent';

const source: Pages = 'home_page2';

type Props = GeoLocationResult;

function HomePage({
  usersCount,
  video,
  regions,
  detectedRegion,
  default_region_name,
  ip_location_details,
}: Props) {
  const { sendEvent } = useEventProvider();

  return (
    <>
      <HomeHeader
        bgColor="#fff"
        onRequestDemoButtonClick={() => {
          sendEvent('request_demo_clicked', null, { section: 'navbar' });
        }}
        onGetStartedButtonClick={() => {
          sendEvent('get_started_free_clicked', null, { section: 'navbar' });
        }}
        onLoginClick={() => {
          sendEvent('login_clicked', null, { section: 'navbar' });
        }}
      />
      <HomeProvider>
        <HomePageContent
          default_region_name={default_region_name}
          detectedRegion={detectedRegion}
          ip_location_details={ip_location_details}
          regions={regions}
          usersCount={usersCount}
          video={video}
        />
      </HomeProvider>

      <PopupManager />
      <GoogleOneTap
        detectedRegion={detectedRegion}
        video={video}
        source={source}
      />
      <style jsx global>{`
        .intercom-lightweight-app-launcher,
        .intercom-app :nth-child(2) {
          @media (max-width: 768px) {
            bottom: 90px !important;
            right: 4px !important;
          }
        }
      `}</style>
    </>
  );
}

export const getServerSideProps = wrapper.getServerSideProps((store) =>
  withAuthentication(
    store,
    withReactDetectDevice(store, async ({ req, res, query }) => {
      const state = store.getState();
      const isAuthenticated = selectIsAuthenticated(state);

      const homeVersion = shouldUseHomePage({
        req,
        res,
        authenticated: isAuthenticated,
      });
      if (homeVersion !== 'v1' && !query.force) {
        return redirectTo(getMustDisplayHomePageRoute(homeVersion));
      }

      const [dynamicInfo] = await Promise.all([
        getDynamicInfoForVideoLandingPage({
          ...query,
          ip: getIp({ req, query }),
        }),
        fetchUserLocation({
          ip: getIp({ query, req }),
        }),
      ]);

      const queryClient = new QueryClient();
      await queryClient.prefetchQuery(
        googleReviewsQueryKey,
        fetchGoogleReviews
      );

      return {
        props: {
          dehydratedState: dehydrate(queryClient),
          usersCount: dynamicInfo?.usersCount,
          video: dynamicInfo?.video,
          regions: dynamicInfo?.regions,
          detectedRegion: dynamicInfo?.detectedRegion,
          default_region_name: dynamicInfo?.default_region_name,
          ip_location_details: dynamicInfo?.ip_location_details,
          default_region_title: dynamicInfo?.default_region_title,
        },
      };
    }),
    { dontRedirectToLoginPage: true }
  )
);

HomePage.pageName = source;
HomePage.withIntercomChatBot = true;
export default HomePage;
